<template>
    <v-dialog v-model="showRisco" width="500" persistent style="height: 650px!important;">
        <v-card style="height: 650px!important; overflow: auto">
            <v-icon @click="changeShow()" class="fechar">mdi-close mdi-color-black</v-icon>
            <v-card-title v-html="$config.conteudo.titulo"></v-card-title>
            <v-card-text class="mt-5" v-html="$config.conteudo.html"></v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-3">
                <v-spacer></v-spacer>
                <v-btn :color="$config.dados.corsecundaria" text @click="changeShow()" v-once>
                    {{ $t_('concordo') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'ModalRisco',
        props: {
            showRisco: Boolean
        },
        watch: {
            showRisco() {
                this.$config.conteudo = {}
                this.$config.getConteudo('termo_compra')
            }
        },
        methods: {
            changeShow() {
                let changeDialog = this.showRisco;
                changeDialog = !changeDialog;
                this.$emit("update-dialog", changeDialog);
            }
        }
    }
</script>

<style scoped>

</style>